@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-currency-selector {
  color: $common-1;
  width: 32vw;
  max-width: none;

  .ModalBackground:before {
    content: none;
  }

  .modal-content {
    object-fit: cover;
    height: 52vh;
    border-radius: 1rem;
  }

  .modal-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    border-radius: 1rem;
    padding: 1rem;
  }

  .innerModalDimmer {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: $common-0;
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.15s linear;

    &.off {
      opacity: 0;
    }
  }

  .gradientText {
    @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
    text-shadow: 0px 1px 2px $common-26;
  }

  .currencySelectorContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 88%;
  }

  .purchaseCompleteContainer {
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .successMessage {
      font-size: 2vmin;
      font-weight: 500;
    }
  }

  .paymentContainer {
    flex: 1;
    height: 100%;
    margin: 0 0.8vmin 0.8vmin 0.8vmin;
    font-size: 1.4vmin;
    font-weight: 300;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      @extend .gradientText;
      font-size: 2vmin;
      font-weight: 500;
    }

    .button {
      width: 75%;
      cursor: pointer;

      &.ieHack {
        height: 17%;
      }
    }

    .casinoDollarsUnavailable {
      width: 92%;
      background: linear-gradient(to right, transparent, $secondary-35, transparent);

      span {
        color: $common-1;
        font-weight: 400;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      cursor: default;
    }

    img {
      position: absolute;
      width: 12.5vmin;
    }

    .label {
      font-size: 1.8vmin;
      text-transform: uppercase;
      font-weight: 600;
      color: $common-1;
      text-align: center;
      z-index: 1;
    }

    .green {
      text-shadow: -1px -1px 0 $secondary-28, 1px -1px 0 $secondary-28, -1px 1px 0 $secondary-28,
        1px 1px 0 $secondary-28;
    }

    .red {
      text-shadow: -1px -1px 0 $secondary-29, 1px -1px 0 $secondary-29, -1px 1px 0 $secondary-29,
        1px 1px 0 $secondary-29;
    }

    .grey {
      text-shadow: -1px -1px 0 $secondary-30, 1px -1px 0 $secondary-30, -1px 1px 0 $secondary-30,
        1px 1px 0 $secondary-30;
    }

    .pink {
      text-shadow: -1px -1px 0 $secondary-31, 1px -1px 0 $secondary-31, -1px 1px 0 $secondary-31,
        1px 1px 0 $secondary-31;
    }
  }

  .purchaseConfirmPopup {
    position: absolute;
    bottom: 20%;
    pointer-events: none;
    opacity: 0;

    &.popIn {
      @keyframes csdcpPopIn {
        0% {
          opacity: 0;
          transform: scale(0.8, 0.8);
        }
        100% {
          opacity: 1;
          transform: scale(1, 1);
        }
      }
      animation: csdcpPopIn 0.25s 0s ease both;
      pointer-events: auto;
    }
    &.popOut {
      @keyframes csdcpPopOut {
        0% {
          opacity: 1;
          transform: scale(1, 1);
        }
        100% {
          opacity: 0;
          transform: scale(0.8, 0.8);
        }
      }
      animation: csdcpPopOut 0.25s 0s ease both;
    }

    .popupBoxTitle {
      font-size: 1.5vmin;
      font-weight: 500;
    }
  }

  .errorContainer {
    width: 100%;
    text-align: center;
    margin-top: 1vmin;
    font-size: inherit;

    &.hidden {
      visibility: hidden;
    }
  }
}
