@import '../../../../shared/assets/style/theme.scss';


.custom-scrollbar-gamepage .os-scrollbar-track {
  height: 16px;
  background-color: $scrollbar-0;
  box-shadow: inset 0 0 4px 2px rgba(0, 0, 0, 0.5);
}

.custom-scrollbar-gamepage .os-scrollbar-handle {
  background-color: white;
  border-radius: 10px;
  height: 16px;
}

.custom-scrollbar-gamepage .os-scrollbar {
  margin-bottom: 30px;
  padding: 0 50px;
}

.custom-scrollbar-gamepage {
  height: 75vh;
}

