@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-upgrade {
  .header {
    position: relative;
    height: 62px;
  }

  .header-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    margin: 0;
  }

  .helpLink {
    position: absolute;
    font-size: 20px;
    font-weight: 500;
    right: 5%;
    color: $common-1;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &:visited,
    &:active,
    &:focus {
      color: $common-1;
    }
  }

  .content {
    padding: 50px 80px;

    .form-container {
      .form-custom-content {
        display: flex;
        flex-wrap: wrap;
      }

      .form-input-group {
        display: inline-block;
        width: 50%;
        padding-right: 10px;
        padding-bottom: 20px;
        padding-left: 10px;

        &.full-width {
          width: 100%;
        }
      }

      .form-input-label {
        left: auto;

        &.with-placeholder {
          top: 0;
          font-size: 15px;
          color: $common-6;
        }
      }

      .form-input-descriptions {
        font-size: 12px;
      }
    }

    &.fullWidthFields {
      .form-input-group {
        width: 100%;
      }
    }

    &.loading {
      .form-input-group {
        opacity: 0.4;
      }
    }
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 32px;

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0 7px;
      text-decoration: underline;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
  }

  .alert {
    text-align: center;
    color: $common-1;
    line-height: 1.2;
    margin: 0 32px 20px 32px;
    padding: 4px;

    &.alert-error {
      background-color: $error-0;
      border: 1px solid $error-1;
    }

    &.alert-success {
      background-color: $success-0;
      border: 1px solid $success-1;
    }

    &.alert-warning {
      color: $common-0;
      background-color: $warning-0;
      border: 1px solid $warning-1;
    }

    h3 {
      font-size: 18px;
      margin: 0;
    }
  }

  .loadingSpinner {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
